console.log("check file");
const mobileMenouElement = document.getElementsByClassName(
    "menu-button-icon"
)[0];

const mobileMenouFullElement = document.getElementById("mobileMenu");
const buttonXElement = document.getElementById("close-menu-button-x");

console.log(mobileMenouElement);
console.log("new check");

if (mobileMenouElement) {
    mobileMenouElement.addEventListener("click", function () {
        console.log("menu-clicked");
        if (mobileMenouFullElement) {
            mobileMenouFullElement.classList.toggle("showMenu");
        }
    });
}

if (buttonXElement) {
    buttonXElement.addEventListener("click", function () {
        console.log(buttonXElement);
        if (mobileMenouFullElement) {
            mobileMenouFullElement.classList.toggle("showMenu");
        }
    });
}

if (document.getElementById("en") || document.getElementById("gr")) {
    x = document.getElementById("en");
    y = document.getElementById("gr");
if (window.location.href.includes("el")) {
    x.style.display = "none";
    y.style.display = "block";
} else if (window.location.href.includes("en")){
    y.style.display = "none";
    x.style.display = "block";
} else {
    y.style.display = "block";
    x.style.display = "none";
}
}


if (window.location.href.indexOf('bookspecific?id=') > 0 ){
    lang = document.getElementById('lang');
    lang.style.pointerEvents = "none";
}

// Deutero Section -> Section Icare